<template>
  <v-container style="max-width: 1500px; max-height: 750px">
    <v-row class="justify-center elevation-4 overlay-container">
      <v-col>
        <v-responsive :aspect-ratio="2/1">
          <span id="puzzle-main-webgl"></span>
        </v-responsive>
      </v-col>
      <v-overlay  :value="isOverlayVisible" absolute>
        <div class="overlay-content">{{ overlayText }}</div>
      </v-overlay>
    </v-row>
    <v-row style="max-width: 1000px">
      <v-col cols="6" md="3" class="justify-start py-0 text-left">
        <v-list-item>
          <v-list-item-content class="py-1 pl-0">
            <v-list-item-subtitle style="font-family: 'Tourney', cursive; max-height: 14px;" >
              time:
            </v-list-item-subtitle>
            <v-list-item-title class="pl-10">
              <span id="timer_space" style="font-family: 'Tourney', cursive;font-size: 250%;"></span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <!-- <v-col cols="6" md="2" class="px-0 pt-4">
        <v-btn
            class="mt-0 mx-1 white--text"
            :disabled="isSuspending"
            :color="button.color"
            @click="suspend"
            v-text="`一時停止`"
        >{{ button.desc }}</v-btn>
        <v-btn
            class="mt-0 mx-0 white--text"
            :disabled="!isSuspending"
            :color="button.color"
            @click="suspend"
            v-text="`再開`"
        ></v-btn>
      </v-col> -->
      <v-col cols="6" md="2" class="px-0 pt-4">
        <v-btn
            class="mt-0 mx-1 white--text"
            :disabled=true
            :color="button.color"
            @click="suspend"
            v-text="`一時停止`"
        >{{ button.desc }}</v-btn>
        <v-btn
            class="mt-0 mx-0 white--text"
            :disabled=true
            :color="button.color"
            @click="suspend"
            v-text="`再開`"
        ></v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="5" md="2" class="pt-1">
        <v-btn
            dark
            small
            block
            class="mt-0 mx-0"
            color="gray"
            @click="toSelect"
            :disabled=true
        >
          あきらめる
        </v-btn>
        <v-btn
            dark
            small
            block
            class="mt-1 mx-0"
            color="gray"
            @click="toHome"
        >
          ホームにもどる
        </v-btn>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-dialog
          v-model="clearDialog"
          width="600"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">やったー！</v-card-title>
          <v-card-text class="text-h5 pa-3">
            <div> クリア時間☞ {{ clearTime }}秒 </div>
            <v-btn
                class="white--text text-capitalize theme--dark ml-1"
                v-for="button in clearButtons"
                :key="button.click"
                :color="button.color"
                v-text="button.desc"
                @click="switchButton(button.click)"
            ></v-btn>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="clearDialog = !clearDialog"
            >
              とじる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog
          v-model="gameOverDialog"
          width="600"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">ざんねん！</v-card-title>
          <v-card-text class="text-h5 pa-3">
            <div>正しい場所ではありませんでした！</div>
            <v-btn
                class="white--text text-capitalize theme--dark ml-1"
                v-for="button in gameOverButtons"
                :key="button.click"
                :color="button.color"
                v-text="button.desc"
                @click="switchButton(button.click)"
            ></v-btn>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="gameOverDialog = !gameOverDialog"
            >
              とじる
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
// import * as Game from './puzzleMultiplayer'

function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
    document.body.appendChild(script);
  });
}

function removeScript(src) {
  const scripts = document.querySelectorAll(`script[src="${src}"]`);
  scripts.forEach(script => script.parentNode.removeChild(script));
}

export default {
  name: "PlayMultiplayer",
  components: {
  },
  data() {
    return {
      button: {
        color:'blue'
      },
      isOverlayVisible: true,
      puzzleSrc: require('@/assets/puzzleMultiplayer.js'),
      overlayText: '通信中...',
      isOwnerHasGone: false,
      timeout: null,
      clearDialog: false,
      gameOverDialog: false,
      isSuspending: false,
      clearButtons: [
        {'click': 'twitter', 'desc': 'Twitterでみせびらかす', 'color': 'blue'},
        {'click': 'toSelect', 'desc': '別の地図であそぶ', 'color': 'gray'},
        {'click': 'toHome', 'desc': 'ホームにもどる', 'color': 'gray'},
      ],
      gameOverButtons: [
        {'click': 'reload', 'desc': 'やりなおす', 'color': 'green'},
        {'click': 'toSelect', 'desc': '別の地図であそぶ', 'color': 'gray'},
        {'click': 'toHome', 'desc': 'ホームにもどる', 'color': 'gray'},
      ],
    }
  },
  methods: {
    suspend: function(){
      if (window.isStarted()) {
        if (this.isSuspending) {
          window.restart()
          this.isSuspending = !this.isSuspending
        } else {
          window.suspend()
          this.isSuspending = !this.isSuspending
        }
      }
    },
    switchButton: function(e){
      switch (e){
        case 'twitter':
          this.openTwitter()
          break
        case 'toHome':
          this.toHome()
          break
        case 'toSelect':
          this.toSelect()
          break
        case 'reload':
          this.puzzleReload()
          break
      }
    },
    toSelect: function(){
      // window.socket.send('ping');
      // const status = {
      //   zoom: this.$store.getters.getMapStatus.zoom,
      //   center: this.$store.getters.getMapStatus.center,
      //   pcs : this.$store.getters.getMapStatus.pcs,
      //   map : this.$store.getters.getMapStatus.map,
      // };
      // this.$store.dispatch('updateMapStatus', status)
      // this.$router.push('/mapuzzle/selectm')
    },
    openTwitter: function(){
      const status = {
        zoom: this.$store.getters.getMapStatus.zoom,
        lat : this.$store.getters.getMapStatus.center[0],
        lng : this.$store.getters.getMapStatus.center[1],
        pcs : this.$store.getters.getPcsList[this.$store.getters.getMapStatus.pcs],
        map : this.$store.getters.getMapStatus.map,
        isSudden: this.$store.getters.getIsSudden,
      };

      const sudden = status.isSudden ? ' ☠サドンデスモード☠ ' : ''
      const text = `${status.pcs}ピース の地図る${sudden}を ${this.clearTime}秒 でクリアしました！
      おなじマップであそぶ☟`
      const hashtags = "地図る,Mapuzzle";
      const url = encodeURIComponent(location.href)
      window.open("https://twitter.com/share?text=" + text + "&hashtags=" + hashtags + "&url=" + url);
    },
    puzzleReload: function(){location.reload()},
    toHome: function(){this.$router.push('/mapuzzle')},
    notReceiveHandler:  function() {
      console.log('NR')
      // if (!this.isOwnerHasGone) {
        this.isOverlayVisible = true;
        this.overlayText = '通信中...';
      // }        
    },
    connectionLostHandler: function() {
      console.log('CL')
      // if (!this.isOwnerHasGone) {
        this.isOverlayVisible = true;
        this.overlayText = '接続が切断されました...';
      // }
    },
    ownerGoneHandler: function() {
      console.log('OG')
      this.isOwnerHasGone = true;
      this.isOverlayVisible = true;
      this.overlayText = '鯖主が退出しました...';
      this.timeout = setTimeout(()=>{this.$router.push('/mapuzzle')}, 5000);
    },
    receiveHandler: function() {
      // 主犯格
      console.log('RH')
      if (!this.isOwnerHasGone) {
        this.isOverlayVisible = false;
        clearTimeout(this.timeout);
      }
    },
  },
  mounted: function() {
    loadScript(this.puzzleSrc)
      .then(() => {
        console.log('Script loaded successfully');
        if (this.$store.state.multiplayer.passphrase === null) this.$router.push('/mapuzzle');
        window.startPuzzle()
      })
      .catch((error) => {
        console.error(error);
      });
  },
  created: function() {
    document.addEventListener('notReceiveOwnerPacket', this.notReceiveHandler);
    document.addEventListener('connectionLost', this.connectionLostHandler);
    document.addEventListener('ownerHasGone', this.ownerGoneHandler);
    document.addEventListener('receivePacket', this.receiveHandler);
  },
  beforeDestroy: function() {
    // this.$store.commit('resetListener');
    document.removeEventListener('notReceiveOwnerPacket', this.notReceiveHandler);
    document.removeEventListener('connectionLost', this.connectionLostHandler);
    document.removeEventListener('ownerHasGone', this.ownerGoneHandler);
    document.removeEventListener('receivePacket', this.receiveHandler);
  },
  computed: {
    cleared() {return this.$store.getters.getCleared},
    gameOver() {return this.$store.getters.getGameOver},
    clearTime() {return this.$store.getters.getClearTime},
  },
  watch: {
    cleared() {this.clearDialog = true},
    gameOver() {this.gameOverDialog = true},
  },
  beforeRouteLeave(to, from, next) {
    window.cleanAllVariable();
    this.$store.commit('resetState');
    removeScript(this.puzzleSrc);
    next();
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Tourney:ital,wght@1,900&display=swap');

#puzzle-main-webgl {
  touch-action: none;
}

.overlay-container {
  position: relative;
  /* width: 300px;
  height: 200px; */
}

.overlay-content {
  color: white;
  font-size: 1.5rem;
}

</style>
